const config = {
   langs: {
       en: {
         yourFriends: "Your Friends",
         tutor: "Tutor",
         learner: "Learner",
         speaker: "Speaker",
         guest: "Guest",
         newProfilePhoto: "New Profile Photo",
         uploadCertificate: "Upload Certificate",
         viewGrades: "View Grades",
         logout: "Logout",
         edit: "Edit",
         email: "E-Mail",
         gender: "Gender",
         userType: "User Type",
         nativeLanguages: "Native Languages",
         about: "Bio",
         patreon: "Patreon",
         country: "Country",
         program: "Program",
         organization: "Organization",
         sendFriendRequest: "Send Friend Request",
         classDetail: 'Class Detail',
         className: 'Class Name',
         addStudent: 'Search Learner',
         name: 'Name',
         actions: 'Actions',
         remove: 'Remove',
         checkAssignments: 'Check Assignments',
         openChat: 'Open Chat',
         pending: 'Pending',
         new: 'New',
         event: 'Event',
         createEvent: 'Create Event',
         eventName: 'Event Name',
         classes: 'Classes',
         deleteClass: 'Delete Class',
         completeClass: 'Complete Class',
         inCompleteClass: 'Incomplete Class',
         showCompleted: 'Show Completed',
         hideCompleted: 'Hide Completed',
         completed: 'Completed',
         createLecture: 'Create Lecture',
         addAssignment: 'Add Assignment',
         manageFolder: 'Manage Folder',
         manageStudents: 'Manage Learners',
         noClassFound: 'No Class Found',
         delete: 'Delete',
         cancel: 'Cancel',
         newAssignment: 'New Assignment',
         updateAssignment: 'Update Assignment',
         selectClass: 'Select Class',
         dueDate: 'Due Date',
         type: 'Type',
         question: 'Question',
         selectFile: 'Select File',
         maxPoint: 'Max Point',
         createAssignment: 'Create Assignment',
         startDate: 'Start Date',
         endDate: 'End Date',
         description: 'Description',
         createClass: 'Create Class',
         newLecture: 'New Lecture',
         attendence: 'Attendance',
         score: 'Score',
         termstatus: 'Term Status',
         lectureName: 'Lecture Name',
         notes: 'Notes',
         classFiles: 'Class Files',
         downloadView: 'Download/View',
         noDataFound: 'No Data Found',
         selectedFriend: 'Selected Friend',
         selectedFileName: 'Selected File Name',
         selectedClass: 'Selected Class',
         classList: 'Class List',
         friendList: 'Friend List',
         libraryList: 'Library List',
         shareLibrary: 'Share library',
         sharedLibraryList: 'Shared Library List',
         moveLibrary: 'Move Library',
         copyToSharedLibrary: 'Copy To Shared Library',
         copyToClassFolder: 'Copy To Class Folder',
         newLibrary: 'New Library',
         info: 'Info',
         getPairingCode: 'Get Pairing Code',
         changePassword: 'Change Password',
         yourTrophies: 'Your Trophies',
         setAsEventBanner: 'Set As Event Banner',
         setAsPrompterText: 'Set As Prompter Text',
         libraryName: 'Library Name',
         createLibrary: 'Create Library',
         attendencePoint: 'Attendence Point',
         activeAssignments: 'Active Assignments',
         invatedUserList: 'Invated User List',
         userList: 'User List',
         viewProfile: 'View Profile',
         files: 'Files',
         noGradeFound: 'This learner has not earn any grades yet',
         newFile: 'New File',
         selectedLibrary: 'Selected Library',
         areYouSureDeleteClass: 'Are you sure to delete this class ?',
         welcome: "Welcome",
         save: 'Save',
         lecture: 'Lecture',
         reading: 'Reading',
         test: 'Test',
         fillintheblanks: 'Fill In The Blanks',
         writing: 'Writing',
         wordMatch: 'Word Match',
         trueFalse: 'True/False',
         dramaA: 'Drama A',
         dramaB: 'Drama B',
         dramaC: 'Drama C',
         dramaD: 'Drama D',
         conversationClub: 'Conversation Club',
         selectLanguage: 'Select Language',
         uploadFile: 'Upload File'
       },
       tr: {
        yourFriends: "Arkadaşların",
        save: 'Kaydet',
         tutor: "Öğretmen",
         learner: "Öğrenci",
         newProfilePhoto: "Yeni Profil Fotoğrafı",
         uploadCertificate: "Sertifika Yükle",
         viewGrades: "Sonuçlarını Gör",
         logout: "Çıkış",
         edit: "Düzenle",
         email: "E-Mail",
         gender: "Cinsiyet",
         userType: "Kullanıcı Tipi",
         nativeLanguages: "Bildiği Diller",
         about: "Bio",
         patreon: "Patreon",
         country: "Ülke",
         program: "Program",
         organization: "Organizasyon",
         sendFriendRequest: "Arkadaşlık isteği gönder",
         classDetail: 'Sınıf Detayı',
         className: 'Sınıf İsmi',
         addStudent: 'Öğrenci Ara',
         name: 'İsim',
         actions: 'Aksiyonlar',
         remove: 'Kaldır',
         checkAssignments: 'Sınavlara Gözat',
         openChat: 'Sohpeti Aç',
         pending: 'Bekleniyor',
         new: 'Yeni',
         classes: 'Sınıflar',
         deleteClass: 'Sınıfı Sil',
         completeClass: 'Sınıfı Tamamla',
         inCompleteClass: 'Sınıfı Tamamla',
         showCompleted: 'Tamamlanmışları Göster',
         hideCompleted: 'Tamamlanmışları Gizle',
         completed: 'Tamamlandı',
         createLecture: 'Yeni Ders',
         addAssignment: 'Sınav Ekle',
         manageFolder: 'Dosyaları Düzenle',
         manageStudents: 'Öğrencileri Düzenle',
         noClassFound: 'Sınıf bulunamadı',
         delete: 'Sil',
         cancel: 'İptal',
         newAssignment: 'Yeni Sınav',
         updateAssignment: 'Sınavı Güncelle',
         selectClass: 'Sınıf Şeç',
         dueDate: 'Bitiş Tarihi',
         type: 'Veri Tipi',
         question: 'Soru',
         selectFile: 'Dosya Şeç',
         maxPoint: 'En yüksek Puan',
         createAssignment: 'Yeni Sınav',
         startDate: 'Başlangıç Tarihi',
         endDate: 'Bitiş Tarihi',
         description: 'Açıklama',
         createClass: 'Sınıf Oluştur',
         newLecture: 'Yeni Ders',
         attendence: 'Katıl',
         score: 'Skor',
         termstatus: 'Term Status',
         lectureName: 'Ders Adı',
         notes: 'Notlar',
         classFiles: 'Sınıf Dosyaları',
         getPairingCode: 'Eşleştirme Kodu',
         downloadView: 'İndir/Önizle',
         noDataFound: 'Veri Bulunamadı',
         selectedFriend: 'Şeçilmiş Arkadaş',
         selectedFileName: 'Şeçilmiş Dosya Adı',
         selectedClass: 'Şeçilmiş Sınıf',
         classList: 'Sınıf Listesi',
         friendList: 'Arkadaş Listesi',
         libraryList: 'Klasör Listesi',
         shareLibrary: 'Klasörü Paylaş',
         sharedLibraryList: 'Paylaşılmış Klasör Listesi',
         copyToSharedLibrary: 'Paylaşımlı Klasöre Kopyala',
         copyToClassFolder: 'Sınıf Klasörüne Kopyala',
         newLibrary: 'Yeni Klasör',
         info: 'Info',
         libraryName: 'Klasör Adı',
         createLibrary: 'Yeni Klasör Oluştur',
         activeAssignments: 'Aktif Dersler',
         viewProfile: 'Profili Gör',
         files: 'Dosyalar',
         newFile: 'Yeni Dosya',
         selectedLibrary: 'Şeçilmiş Klasör',
         areYouSureDeleteClass: 'Bu sınıfı silmek istediğinize eminmisiniz?',
         welcome: "Hoşgeldiniz",
         save: 'Kaydet',
         lecture: 'Ders',
         reading: 'Okuma',
         test: 'Test',
         fillintheblanks: 'Boşluk Doldurmaca',
         writing: 'Kompozisyon',
         wordMatch: 'Kelime Eşleştirme',
         trueFalse: 'Doğru/Yanlış',
         dramaA: 'Drama A',
         dramaB: 'Drama B',
         dramaC: 'Drama C',
         dramaD: 'Drama D',
         conversationClub: 'Conversation Club',
         selectLanguage: 'Dil şeç',
         uploadFile: 'Dosya Yükle'
       }
   }
}
export default config